<template>
    <div class="Recruitment Wrapper-section" id="RecruitmentPage">
        <div class="Trigger TriggerTop" id="RecruitmentTriggerTop"></div>
        <div class="Recruitment-container">
            <div class="RecruitmentIntro" id="Recruitment">
                <h2 class="title RecruitmentIntro-title">We’re always looking for the right people.</h2>
                <div class="RecruitmentIntro-text">
                    <h3 class="subtitle RecruitmentIntro-subtitle">If you like the sound of what we’re doing, get in touch.<br /><span>How you do it is up to you…</span>
                    </h3>
                </div>
            </div>
            <figure class="RecruitmentIllustration">
                <img :src="`${this.publicPath}img/waitingFox.jpg`" alt="" />
            </figure>
        </div>
        <div class="Trigger TriggerBottom" id="RecruitmentTriggerBottom"></div>
    </div>
</template>

<script>
    export default {
        name: 'Recruitment',
        data() {
            return {
                publicPath: process.env.BASE_URL,
                sceneTimer: null,
                scrollMagicTrigger: ['RecruitmentTriggerTop'],
                scrollMagicSectionId: ['RecruitmentPage']
            };
        },
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);
                const SMC = new this.$scrollmagic.Controller();

                for (let i = 0; i < this.scrollMagicSectionId.length; i++) {
                    const scene = new this.$scrollmagic.Scene({
                        triggerElement: `#${this.scrollMagicTrigger[i]}`,
                        triggerHook: 'onEnter'
                    }).setClassToggle(`#${this.scrollMagicSectionId[i]}`, 'visible'); // add class to reveal
                    SMC.addScene(scene);
                }
            }
        }
    };
</script>
